.reasons-to-join-wrapper {
  text-align: center;
  /* border: solid green; */
  height: 100%;
}

.reasons-to-join-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .reasons-to-join-content {
    flex-direction: row;
  }
}


.reasons-title{
margin-bottom: 22px;
}