@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  padding: 0;
  margin: 0;
}
/* .carousel {
  overflow: hidden;
  height: 100%;
} */
.slider-frame {
  height: 100% !important;
}
.slider {
  height: 80% !important;
}
.text-align-center {
  text-align: center;
  /* border: solid green; */
  width: 100%;
}

.slider:focus {
  outline: none !important;
}
.slider-list:focus {
  outline: none !important;
}
.slider-frame:focus {
  outline: none !important;
}
.slider-slide:focus {
  outline: none !important;
}
.overflow-hidden {
  overflow: hidden;
}
.project-container {
  width: 100%;
  min-width: 1000px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 84px;
}
@media (min-width: 415px) {
  .project-container {
    /* height: 100vh; */
    height: 100%;
    min-width: 875px;
    padding-top: 0;
    /* background: #222229; */
  }
}

.item {
  position: relative;
  /* border: solid 1px black; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fish-blob {
  height: 100%;
  width: 100%;
  z-index: -1;
  /* border: solid red; */
}
.BrainhubCarousel__arrow--disable {
  pointer-events: auto !important;
}

.font-weight-300 {
  font-weight: 300;
}
.font-weight-links {
  font-weight: 600;
}

.carousel-wrapper {
  width: 100%;
  z-index: 4000;
  margin-top: 73px;

  outline: none;
  display: none;
  /* border: solid green; */
}

@media (min-width: 415px) {
  .carousel-wrapper {
    display: block;
    width: 85%;
    /* padding-top: 40px; */
    max-width: 1200px;
    /* min-width: 900px; */
    /* border: solid blue; */
  }
}

.carousel-wrapper-phone {
  width: 85%;
  z-index: 4000;
  outline: none;
  /* border: solid green; */
}

@media (min-width: 415px) {
  .carousel-wrapper-phone {
    display: none;
    width: 85%;
    max-width: 1200px;
    /* min-width: 900px; */
    /* border: solid blue; */
  }
}

.about-us-title-projects {
  margin: unset;
  /* font-size: 1.8vw; */
  font-size: 26px;
  font-weight: 900;
}
@media (min-width: 415px) {
  .about-us-title-projects {
    font-size: 42px;
  }
}
@media (min-width: 1100px) {
  .about-us-title-projects {
    /* margin-bottom: 92px; */
  }
}

.dots-style {
  background: white;
}

.min-width-projects {
}

@media (min-width: 415px) {
  .min-width-projects {
    min-width: 800px;
  }
}

.dots-wrapper {
  position: relative;
  padding-top:20px;
}
@media (min-width: 415px) {
  .dots-wrapper {
    top: 0px;
    padding-top: 36px;
  }
}

.project-dots {
  width: 65%;
}
@media (min-width: 415px) {
  .project-dots {
    width: 75%;
  }
}

.project-arrow {
  position: relative;
  /* top: 120px; */
}
.project-arrow:hover{
  background-color:  rgba(0, 0, 0, 0.01) !important;
}
.project-arrow-disabled{
  position: relative;
}

.project-arrow-disabled:hover{
  background-color: unset !important;
}
.project-arrow-left {
}
@media (min-width: 415px) {
  .project-arrow {
    top: 0px;
  }
}

/* .home-container {
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  min-width: 330px;
  margin: unset;
  display: flex;
  align-items: center;
}
@media (min-width: 415px) {
  .home-container {
    min-height: 100px;
  }
}
@media (min-width: 1024px) {
  .home-container {
    overflow: hidden;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
} */

.home-container-alternative {
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  min-width: 330px;
  /* border: solid green; */
  margin: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 415px) {
  .home-container-alternative {
    min-height: 700px;

    max-height: 1300px;
    justify-content: flex-end;

  }
}


@media (min-width: 1024px) {
  .home-container-alternative {
    position: relative;
  }
}


.bg-bottom-left {
  position: fixed;
  /* min-width: 220px; */
  max-width: 180px;
  display: none;
  left: 0;
  bottom: 0;
  width: 64vw;
}
/* backgrounds */
@media (min-width: 415px) {
  .bg-bottom-left {
    width: 22vw;
    min-width: 294px;
  }
}
.bg-top-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 212px;
}
@media (min-width: 415px) {
  .bg-top-left {
    width: 300px;
    /* min-width: 300px; */
    /* border: solid wheat; */
  }
}
@media (min-width: 800px) {
  .bg-top-left {
    /* max-width: 250px; */
    /* border: solid orchid; */
  }
}

.bg-top-right {
  display: none;
}

@media (min-width: 415px) {
  .bg-top-right {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 28vw;
  }
}
@media (min-width: 800px) {
  .bg-top-right {
    width: 22vw;
    max-width: 250px;
    /* border: solid orchid; */
  }
}

/* woman */
.lady-body {
  width: 100%;
  height: 100%;
}

.woman-container {
  height: 100%;
  z-index: 1;
  position: relative;
  width: 78%;
  min-width: 250px;
}
@media (min-width: 415px) {
  .woman-container {
    /* width: 50%; */
    width: 400px;
  }
}

@media (min-width: 800px) {
  .woman-container {
    /* min-width: 400px; */
    margin-left: 200px;
    top: 0px;
    height: 561px;
    /* width: 26%; */
  }
}

@media (min-width: 1900px) {
  .woman-container {
    height: 700px;
    width: 500px;
    margin-left: 300px;
    top: 90px;
  }
}

/* @media (width: 1024px) and (height: 1366px) {
  .woman-container {
  }
} */
/* @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .woman-container {
    height: 70%;
    z-index: 1;
    position: relative;
    /* width: 500px; */
/* margin: 0 auto; */
/* margin: 0 auto; */
/* border: solid green; */

/* margin: 0 auto; */
/* margin: 0 auto; */
/* border: solid green; */
/* margin-top: 20vh; */

.lady-arms {
  z-index: 5000;
  position: absolute;
  top: 9.1%;
  left: -4.3%;
  width: 117.4%;
  z-index: 600;
}

/* blobs */
.center-blob {
  position: absolute;
  width: 55%;
  top: 45%;
  left: 25%;
}

.top-blob {
  position: absolute;
  width: 35%;
  right: -7%;
  top: 15%;
  z-index: -1;
}

.bottom-blob {
  position: absolute;
  width: 60%;
  left: 25%;
  bottom: 0;
}

/* links */
.link {
  position: absolute;
  color: white;
  font-weight: 200;
  z-index: 8000;
}

.left-blob-link {
  /* right: 5%;
  top: -24%; */
  right: 0%;
  top: -24%;
}
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
      .left-blob-link {
        /* right: 5%;
        top: -24%; */
        right: 0%;
        top: -20%
}}
@media (min-width: 415px) {
  .left-blob-link {
    top: -17%;
    font-size: 24px;
  }
}
@media (min-width: 1000px) {
  .left-blob-link {
    top: -20%;
    right: 115%;
    font-size: 1.3em;
  }
}
/* @media (min-width: 1000px) {
  .left-blob-link {
    font-size: 18px;
  }
} */
@media (min-width: 1900px) {
  .left-blob-link {
    font-size: 1.8em;
  }
}

.top-blob-link {
  right: -1%;
  color: #000000;
  top: 18%;
  font-size: 14px;
}
@media (min-width: 415px) {
  .top-blob-link {
    right: 0%;
    /* border: solid blue;
     */
    font-size: 1.20em;
  }
}

@media (min-width: 1900px) {
  .top-blob-link {

    font-size: 1.55em;
  }
}

.bottom-blob-link {
  left: 35%;
  bottom: 2.5%;
  font-weight: 900;
  font-size: 14px;
}

@media (min-width: 415px) {
  .bottom-blob-link {
    bottom: 3%;
    font-size: 1.15em;

  }
}
@media (min-width: 1900px) {
  .bottom-blob-link {
    font-size: 1.25em;
  }
}

.center-blob-link {
  z-index: 10000;
  top: 60%;
  left: 43%;
  font-size: 100%;
  font-weight: 900;
}
@media (min-width: 415px) {
  .center-blob-link {
    font-size: 22px;
  }
}

@media (min-width: 1900px) {
  .center-blob-link {
    font-size: 1.55em;

  }
}
.planet-1 {
  display: none;
}
@media (min-width: 800px) {
  .planet-1 {
    display: block;
    position: absolute;
    top: 664px;
    right: 24px;
    /* border: solid green; */
    max-width: 200px;
    width: 20vw;
  }
}
@media (min-width: 1000px) {
  .planet-1 {
    right: 64px;
    /* border: solid purple; */
  }
}
.planet-2 {
  display: none;
}
@media (min-width: 800px) {
  .planet-2 {
    display: block;
    position: absolute;
    top: 424px;
    max-width: 200px;
    left: 5vw;
    /* border: solid blue; */
    width: 20vw;
  }
}
/* @media (min-width: 769px) {
  .planet-2 {
    top: 624px;
  }
} */

@media (width: 1024px) and (height: 1366px) {
  .planet-2 {
    top: 624px;
  }
}
.planet-3 {
  position: absolute;
  top: 4px;
  right: 0;
  /* border: solid purple; */
  width: 20vw;
  z-index: 1;
}
@media (min-width: 415px) {
  .planet-3 {
    top: 100px;
    right: 5vw;
    /* height: 40vh; */
  }
}
/* @media (min-width: 800px) {
  .planet-3 {
    right: 4%;
    width: 18vw;
    max-width: 200px;

    /* height: 40vh;
  }
} */

@media (min-width: 800px) {
  .planet-3 {
    right: 6%;
    width: 10vw;
    min-width: 150px;
    max-width: 200px;
    /* border: solid wheat; */

    /* height: 40vh; */
  }
}

@media (min-width: 1500px) {
  .planet-3 {
    right: 8%;

    /* height: 40vh; */
  }
}
.home-about-tex-dark {
  color: white;
}

.home-about-text-light {
  color: black;
}
@media (min-width: 1000px) {
  .home-about-text-light {
    color: white;
  }
}

* {-webkit-font-smoothing: antialiased;}
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-dark {
  background: #222229;
}
.p-color-light {
  color: white;
}
.p-color-dark {
  color: black !important;
}
html,
body {
  margin: 0;
  padding: 0;
}

p {
  /* font-family: Poppins; */
  /* font-size: 20px; */
  font-weight: 200;
}
.wrapper {
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/background.58ae5dd7.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
}

.pyramid {
  width: 100px;
  height: 100px;
  /* background-color: green; */
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 1;
}
.timer {
  display: none;
}
@media (min-width: 415px) {
  .timer {
    display: block;
    position: absolute;
    /* color: white; */
    top: 30px;
    right: 2%;
    font-size: 24px;
  }
}
.location-icon {
  position: relative;
  height: 12px !important;
  margin: 0px !important;
  margin-right: 0px !important;
  margin-top: 6.6px !important;
}
@media (min-width: 415px) {
  .location-icon {
    height: 16px !important;
    margin-right: 5px !important;
    margin-top: 5px !important;
  }
}
.contact-alternative {
  display: flex;
  justify-content: center;
  /* border: solid red; */
  position: relative;
  padding-top: 16px;
  z-index: 1;
  /* top: 10%; */
}
@media (min-width: 415px) {
  .contact-alternative {
    /* padding-bottom: 100px; */
    padding-left: 32px;
    justify-content: flex-start;
  }
}


@media (width: 1024px) and (height: 1366px) {
  .contact-alternative {
    /* margin-top: 30%; */
    /* border: solid blue; */
  }
}
.contact {
  position: absolute;
  display: flex;
  /* color: white; */
  top: 570px;
  left: 9%;
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
}
@media (min-width: 361px) {
  .contact {
    top: 590px;
  }
}
@media (min-width: 376px) {
  .contact {
    left: 13%;
    top: 660px;
  }
}
@media (min-width: 415px) {
  .contact {
    left: 48px;
    top: 1140px;
  }
}
@media (width: 375px) and (height: 812px) {
  .contact {
    top: 700px;
  }
}
/* ipad  */

@media (width: 768px) and (height: 1024px) {
  .contact {
    left: 48px;
    top: 950px;
    /* border: solid green; */
  }
}
/* ipad proo */
@media (width: 1024px) and (height: 1366px) {
  .contact {
    left: 48px;
    top: 1300px;
  }
}
.contact-delimiter {
  margin-left: 8px;
  margin-right: 8px;
  height: 1em;
}
/* @media (min-width: 415px) {
  .contact-delimiter {
    margin-left: 20px;
    margin-right: 20px;
    height: 2em;
  }
} */
.contact-delimiter-email {
  display: none;
  margin-left: 8px;
  margin-right: 8px;
}
@media (min-width: 600px) {
  .contact-delimiter-email {
    display: block;
  }
}

.contact-emails-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .contact-emails-container {
    flex-direction: row;
  }
}
.contact-address-text {
  min-width: 105px;
}

.contact-text-home {
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 16px;
}
@media (min-width: 415px) {
  .contact-text-home {
    font-size: 14px;
  }
}
.img-container {
  height: 90vh;
  width: 80%;
  display: flex;
  justify-content: center;
  position: relative;
}

.woman {
  height: 93%;
  align-self: flex-end;
  z-index: -1;
}

.arms {
  position: absolute;
  height: 87%;
  margin-left: 20px;
  align-self: flex-end;
  width: 48.5%;
  z-index: 1;
}

.who-we-are {
  position: absolute;
  top: 0px;
  left: 6%;
}

.us-blob {
  position: absolute;
  top: 0.2rem;
  left: 12rem;
}

.us-blob-text {
  color: white;
  position: absolute;
  top: 4.2rem;
  left: 17rem;
  text-align: right;
  z-index: 2;
}

.work-blob {
  position: absolute;
  bottom: 0;
}

.work-link {
  position: absolute;
  width: 188px;
  height: 30px;
  bottom: 4.8vh;
  left: 42%;
  z-index: 2;
}

.work {
  position: absolute;
  bottom: 4.5vh;
  background-color: rgb(255, 255, 255, 0.5);
  width: 188px;
  height: 25px;
  margin-left: 5px;
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
}

.rectangle {
  position: absolute;
  width: 240px;
  bottom: 0;
  left: 41%;
}

.projects-blob {
  position: absolute;
  z-index: 0;
  bottom: 9vh;
  margin-left: 2rem;
}

.projects {
  position: absolute;
  top: 65%;
  font-weight: bolder;
}

.projects-link {
  position: absolute;
  width: 12rem;
  height: 12rem;
  bottom: 17%;
  left: 43%;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
}

.about-blob {
  position: absolute;
  width: 180px;
  margin-left: 19%;
  top: 22%;
  z-index: 1;
}

.about-link {
  position: absolute;
  width: 6rem;
  height: 6rem;
  top: 25%;
  right: 28%;
  cursor: pointer;
  z-index: 2;
}

.triangle {
  position: absolute;
  width: 180px;
  margin-left: 18.5%;
  top: 19%;
  z-index: 2;
}

.about {
  position: absolute;
  top: 29%;
  margin-left: 18%;
  z-index: 1;
}

.blob-enter {
  opacity: 0.01;
}

.blob-enter.blob-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.blob-leave {
  opacity: 1;
}

.blob-leave.blob-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.logo {
  width: 60px;
  z-index: 2;
  position: absolute;
  top: 14px;
  left: 35px;
  max-width: 400px;

  /* border: solid blue; */
}
@media (min-width: 415px) {
  .logo {
    width: 80px;
    z-index: 2;
    position: absolute;
    top: 14px;
    left: 35px;
    max-width: 400px;
  
    /* border: solid blue; */
  }
}

.margin-y-woman-container {
  margin-top: 80px;
  margin-bottom: 0;
  margin-bottom: 50px;

}
@media (min-width: 415px) {
  .margin-y-woman-container {
    margin-top: 80px;
    margin-bottom: 10px;

  }
}
@media (min-width: 1900px) {
  .margin-y-woman-container {
    margin-bottom: 100px;
  }
}
.p-color-white {
  color: white;
}
.p-color-dark {
  color: black;
}
.remove-link-decoration {
  text-decoration: none;
  color: inherit;
}
.remove-link-color {
  color: inherit;
}
.border-solid-green {
}

.font-weight-900 {
  font-weight: 900;
}

.font-size-32px {
  font-size: 22px !important;
}
@media (min-width: 800px) {
  .font-size-32px {
  font-size: 32px !important;
}}

.not-found-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  align-items: center; 
}

.not-found-image{
  width: 80%;
  margin: 0 10%;
  height: auto;}

.not-found-text-dark-mode{
  color:white
}
.not-found-text-light-mode{
  color:black
}
.margin-top-42px{
  margin-top: 42px !important;
}
.margin-top-40px {
  margin-top: 40px !important;


}

.alternative-slogan{
  text-align:'center'


}
.BrainhubCarousel {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
/* @media (min-width: 415px) {
  .BrainhubCarousel {
    min-height: unset;
  }
} */
ul li {
  color: #67b383;
}

.BrainhubCarousel__dots {
}
.item {
  position: relative;
  /* border: solid 1px black; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-project-bolder {
  font-weight: bolder;
  margin: unset;
}
.p-project-color {
  color: black;
}
.p-project-color-green {
  color: #6fbe95;
}
.font-size-16px {
  font-size: 16px;
  font-weight: 700;
}
.font-size-15px {
  font-size: 15px;
  font-weight: 700;
}

.font-size-13px 
{
  font-size: 13px;
  font-weight: 700;
}

.item-name {
  margin: 8px 0;
  /* margin-top: 92px; */
  margin-top:40px ;
  font-size: 24px;
  text-align: center;
  /* border: solid purple; */
}
@media (min-width: 415px) {
  .item-name {
    margin-top:unset;
    font-size: 32px;
  }
}

@media (min-width: 1000px) {
  .item-name {
    padding-right: 42px;
    /* border: solid wheat; */
  }
}

@media (min-width: 1000px) {
  .item-name {
    /* margin-top: 12%; */
    /* margin-top: 92px; */

    /* border: solid purple; */
  }
}

.item-date {
  /* border: solid red; */
  display: flex;
  width: 50%;
  text-align: center;
}
.item-keywords {
  /* border: solid black 1px; */
  padding: 2px;
  display: flex;
}
.item-about {
  /* border: solid black; */
}

.item-about-sub {
  /* border: solid blue; */
  display: none;
  font-size: 16px;
  /* border: solid orchid; */

  /* border: solid; */
}
@media (min-width: 415px) {
  .item-about-sub {
    display: block;
    /* border: solid yellow; */
  }
}

.item-about-sub-phone {
  display: block;
  border-radius: 0 0 4px 4px;
  background: #f7f7f7;
  padding: 0 8px 2px 16px;

  /* border: solid green; */
}

@media only screen and (min-width: 415px) {
  .item-about-sub-phone {
    display: none;
  }
}

.blob-container {
  justify-content: center;
  padding: 5%;
  align-items: center;
}
.project-slide-blob-wrapper {
  outline: none;
  /* width: 100%; */
  display: none;

  /* max-height: 232px; */

  /* border: solid orange; */
}
@media (min-width: 1300px) {
  .project-slide-blob-wrapper {
    max-height: unset;
    display: flex;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    height: 100%;
  }
}

.project-slide-wrapper {
  outline: none;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  margin: 0 auto;
  /* border: solid orange; */
}
@media (min-width: 415px) {
  .project-slide-wrapper {
    padding-top: 108px;
    padding-top: 0px;
  }
}
@media (min-width: 1000px) {
  .project-slide-wrapper {
    padding-top: 0px;
    outline: none;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    /* height: 750px; */
    margin: 0 auto;
    margin-left: 42px;
  }
  /* border: solid orange; */
}
@media (min-width: 1100px) {
  .project-slide-wrapper {
    flex-direction: row;

    /* border: solid orange; */
  }
}

.about-content-wrapper {
  overflow: auto;
  height: 100%;
  /* border: solid green; */
  max-height: 315px;
}
@media (min-width: 415px) {
  .about-content-wrapper {

    max-height: unset;
    /* border: solid green; */
  }
}

@media (min-width: 1100px) {
  .about-content-wrapper {
    /* width: 694px; */
    max-height: unset;
    /* border: solid wheat; */
  }
}

.project-slide-container {
  /* border: solid green; */

  /* max-width: 800px; */
  max-height: 800px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 415px) {
  .project-slide-container {
    min-width: 400px;
    min-height: 400px;
  }
}
.blob-project {
  width: 178px;
  height: 178px;
  position: relative !important;
}
@media (min-width: 415px) {
  .blob-project {
    top: 74px;
    width: 300px;
  }
}

.project-scroll-icon {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  width: 100%;
}

.p-content {
  font-size: 16;
}

@media (min-width: 415px) {
  .p-project-content {
    font-size: 16px;
    padding-right: 28px;
  }
}

.margin-0 {
  margin: 0;
}
.padding-bottom-10px {
  padding-bottom: 10px;
}

.canvas {
  /* position: fixed; */
  position: absolute;
  /* top: 450px; */
  /* window: 100%; */
  height: 100%;
  z-index: 500;
  /* border: solid red; */
}

.blob-center {
  left: -20%;
  top: 17%;
}

.blob-right {
  left: 14%;
  top: -25%;
  z-index: -1;
}
.blob-left {
  display: none;
  /* left: -500px; */
}
/* @media (min-width: 1025px) IPAD PRO { */
@media (min-width: 1000px) {
  .blob-left {
    /* border: solid red; */
    display: block;
    left: -102%;
    top: -50%;
    /* left: -500px; */
  }
}

.blob-bottom {
  left: -22%;
  top: 42%;
}

.header-about-us {
  margin-top: 20px;
  z-index: 4;
  text-align: center;
  font-weight: 100;

}
@media (min-width: 415px) {
  .header-about-us {
    margin-top: 73px;
    z-index: 4;
  }
}

.about-us-title {
  margin: unset;
  /* font-size: 1.8vw; */
  font-size: 26px;

  font-weight: 900;
  /* margin-bottom: 79px; */
}
@media (min-width: 415px) {
  .about-us-title {
    font-size: 42px;
  }
}
.body-about-us {
  height: 100%;
  width: 80%;
  z-index: 4;
}
@media (min-width: 415px) {
  .body-about-us {
    height: 100%;
    width: 425px;
  }
}
@media (min-width: 900px) {
  .body-about-us {
    width: 700px;
  }
}
@media (min-width: 1100px) {
  .body-about-us {
    width: 900px;
  }
}

.body-subheader {
  font-weight: bolder;
  font-size: 16px;
}

.blob-about-1 {
  position: absolute;
  top: 148px;
  z-index: 1;
  width: 200px;
  height: 180px;
  max-width: 200px;
}

/* .blob-about-2 {
  display: none;
} */
/* @media (min-width: 415px) { */
.blob-about-2 {
  position: relative;
  z-index: 1;
  top: 438px;
  right: 0;
  /* border: solid green; */
  position: absolute;
  display: block;
  height: 480px;
}
/* } */
@media (min-width: 1100px) {
  .blob-about-2 {
    top: 238px;
  }
}

.about-text {
  font-size: 22px;
  font-weight: 300;
}

.reason-to-join {
  padding: 2px 16px 2px 16px;
  text-align: left;
  margin-bottom: 16px;
}
@media (min-width: 900px) {
  .reason-to-join {
    width: 32%;
  }
}

.reason-title {
  font-size: 16px;
  font-weight: 700;
  /* font-size: 1.15em; */
}
.reason-title::after {
  content: "";
  border-top: 2px solid #000;
  display: block;
  width: 25px;
  margin: 10px 0;
}
.reason-title-dark-mode{
  font-size: 16px;
  font-weight: 700;
  /* font-size: 1.15em; */
}
.reason-title-dark-mode::after {
  content: "";
  border-top: 2px solid white;
  display: block;
  width: 25px;
  margin: 10px 0;
}
.reasons-body {
  font-size: 16px;
  font-weight: 300;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
@media (min-width: 900px) {
.reasons-body {
  min-height: 280px;
}
}

.reasons-link {
  font-weight: 500;
}

.reasons-link:before {
  speak: none;
  font-style: normal;
  font-weight: 500;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  font-size: 16px;
  /* content: "\e602"; */
  content: "→";
  margin-right: 5px;
}
/* .reason-delimiter {
  border-top: solid black;
  width: 25px;
} */

.reasons-to-join-wrapper {
  text-align: center;
  /* border: solid green; */
  height: 100%;
}

.reasons-to-join-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 900px) {
  .reasons-to-join-content {
    flex-direction: row;
  }
}


.reasons-title{
margin-bottom: 22px;
}
.display-flex-row {
  display: flex;
  flex-direction: row;
}
.work-content {
  height: 100%;
  width: 90%;
  margin: auto;
  padding-left: 16px;
}
@media (min-width: 360px) {
  .work-content {
    width: 100%;
  }
}
@media (min-width: 376px) {
  .work-content {
    padding-left: 0;
  }
}

.work-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1000px) {
  .work-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 6% 0 40px 0;
  }
}
.work-content-row {
  display: flex;
  width: 100%;
  max-width: 350px;
  flex-direction: column;
}

@media (min-width: 415px) {
  .work-content-row {
    min-width: 600px;
    box-shadow: unset !important;
  }
}
@media (min-width: 600px) {
  .work-content-row {
    box-shadow: unset !important;
  }
}

@media (min-width: 1100px) {
  .work-content-row {
    display: flex;
    flex-direction: row-reverse;
    min-width: 1000px;
    width: 100%;
    min-height: 400px;
    height: 100%;
    justify-content: space-evenly;
  }
}

.work-content-row-text {
  width: 100%;
}
@media (min-width: 800px) {
  .work-content-row-text {
    width: 100%;
  }
}
.work-content-row-animation {
    width: 100%;
    padding-left: 17px;

}
@media (min-width: 415px) {
.work-content-row-animation {
  padding-left: unset
  
}}
.animation-phone-dark-background {
  background: #34343e;
  border-radius: 4px 4px 0 0;
}
@media only screen and (min-width: 415px) {
  .animation-phone-dark-background {
    background: inherit;
    border-radius: unset;
  }
}

@media (min-width: 800px) {
  .work-content-row-animation {
    margin-left: 107px;
    width: 100%;
    height: 50%;
  }
}

.height-100procent {
  height: 100%;
}
.blob-work-wrapper {
  width: 100%;
}
.contact-blob {
  position: relative;
}
@media (min-width: 415px) {
  .contact-blob {
    width: unset;
    position: relative;
  }
}
@media (min-width: 1100px) {
  .contact-blob {
    top: 660px;
    position: absolute;
    right: 10vw;
  }
}

.contact-text {
  position: relative;
  top: 46px;
  left: 53%;
  width: 201px;
  text-align: center;
}
@media (min-width: 415px) {
  .contact-text {
    left: 92.5%;
    top: 128px;
  }
}
@media (min-width: 1000px) {
  .contact-text {
    height: 300px;
    left: 54%;
    width: 201px;
    top: 94px;
  }
}
.p-contact-text {
  z-index: 10000;
  color: white;
  position: relative;
}
.blob-work {
  position: relative;
  width: 187px;
  height: 187px;
  left: 57%;
  top: -73px;
}
@media (min-width: 415px) {
  .blob-work {
    left: 83%;
    width: 246px;
    height: 246px;
    top: -23px;
  }
}
@media (min-width: 1000px) {
  .blob-work {
    top: 0;
    left: 43%;
    position: absolute;
  }
}
.work-expand {
  display: flex;
  justify-content: space-between;
}
.line-height-position {
  line-height: 1.4em;
}

.margin-bottom-79px {
  margin-bottom: 79px;
}

.container {
  display: flex;
  justify-content: center;
  /* border: solid wheat; */
  width: 94%;
  /* z-index: 9000; */
}
@media (min-width: 415px) {
  .container {
    width: 100%;
  }
}

.svg-eye {
  pointer-events: none;
  position: absolute;
  top: 10%;
  left: 50%;
  width: 20%;
  max-width: 600px;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 10em;
  z-index: 9000;
  /* border: solid blue;*/
}

.left-eye {
  z-index: 6000;
  top: 22.05%;
  left: 43%;
  width: 10%;
}
.right-eye {
  z-index: 6000;
  top: 21.65%;
  left: 59.1%;
  width: 10%;
}
@media (min-width: 415px) {
  .left-eye {
  z-index: 6000;
  top: 22.4%;
  left: 43%;
  width: 10%;
}}
@media (min-width: 415px) {

.right-eye {
  z-index: 6000;
  top: 21.9%;
  left: 59.1%;
  width: 10%;
}
}

#bg {
  fill: #2da1dc;
}

#eye > path:nth-child(2) {
  fill: #67b383;
}
#eye > g:first-of-type > path:nth-child(1) {
  fill: #67b383;
}
#eye > g:first-of-type > path:nth-child(2) {
  fill: #67b383;
}
#eye > g:last-of-type > path:nth-child(2) {
  fill: #231f20;
}

