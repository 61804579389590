.header-about-us {
  margin-top: 20px;
  z-index: 4;
  text-align: center;
  font-weight: 100;

}
@media (min-width: 415px) {
  .header-about-us {
    margin-top: 73px;
    z-index: 4;
  }
}

.about-us-title {
  margin: unset;
  /* font-size: 1.8vw; */
  font-size: 26px;

  font-weight: 900;
  /* margin-bottom: 79px; */
}
@media (min-width: 415px) {
  .about-us-title {
    font-size: 42px;
  }
}
.body-about-us {
  height: 100%;
  width: 80%;
  z-index: 4;
}
@media (min-width: 415px) {
  .body-about-us {
    height: 100%;
    width: 425px;
  }
}
@media (min-width: 900px) {
  .body-about-us {
    width: 700px;
  }
}
@media (min-width: 1100px) {
  .body-about-us {
    width: 900px;
  }
}

.body-subheader {
  font-weight: bolder;
  font-size: 16px;
}

.blob-about-1 {
  position: absolute;
  top: 148px;
  z-index: 1;
  width: 200px;
  height: 180px;
  max-width: 200px;
}

/* .blob-about-2 {
  display: none;
} */
/* @media (min-width: 415px) { */
.blob-about-2 {
  position: relative;
  z-index: 1;
  top: 438px;
  right: 0;
  /* border: solid green; */
  position: absolute;
  display: block;
  height: 480px;
}
/* } */
@media (min-width: 1100px) {
  .blob-about-2 {
    top: 238px;
  }
}

.about-text {
  font-size: 22px;
  font-weight: 300;
}
