/* .home-container {
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  min-width: 330px;
  margin: unset;
  display: flex;
  align-items: center;
}
@media (min-width: 415px) {
  .home-container {
    min-height: 100px;
  }
}
@media (min-width: 1024px) {
  .home-container {
    overflow: hidden;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
} */

.home-container-alternative {
  height: 100vh;
  min-height: 400px;
  overflow: hidden;
  min-width: 330px;
  /* border: solid green; */
  margin: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 415px) {
  .home-container-alternative {
    min-height: 700px;

    max-height: 1300px;
    justify-content: flex-end;

  }
}


@media (min-width: 1024px) {
  .home-container-alternative {
    position: relative;
  }
}


.bg-bottom-left {
  position: fixed;
  /* min-width: 220px; */
  max-width: 180px;
  display: none;
  left: 0;
  bottom: 0;
  width: 64vw;
}
/* backgrounds */
@media (min-width: 415px) {
  .bg-bottom-left {
    width: 22vw;
    min-width: 294px;
  }
}
.bg-top-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 212px;
}
@media (min-width: 415px) {
  .bg-top-left {
    width: 300px;
    /* min-width: 300px; */
    /* border: solid wheat; */
  }
}
@media (min-width: 800px) {
  .bg-top-left {
    /* max-width: 250px; */
    /* border: solid orchid; */
  }
}

.bg-top-right {
  display: none;
}

@media (min-width: 415px) {
  .bg-top-right {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 28vw;
  }
}
@media (min-width: 800px) {
  .bg-top-right {
    width: 22vw;
    max-width: 250px;
    /* border: solid orchid; */
  }
}

/* woman */
.lady-body {
  width: 100%;
  height: 100%;
}

.woman-container {
  height: 100%;
  z-index: 1;
  position: relative;
  width: 78%;
  min-width: 250px;
}
@media (min-width: 415px) {
  .woman-container {
    /* width: 50%; */
    width: 400px;
  }
}

@media (min-width: 800px) {
  .woman-container {
    /* min-width: 400px; */
    margin-left: 200px;
    top: 0px;
    height: 561px;
    /* width: 26%; */
  }
}

@media (min-width: 1900px) {
  .woman-container {
    height: 700px;
    width: 500px;
    margin-left: 300px;
    top: 90px;
  }
}

/* @media (width: 1024px) and (height: 1366px) {
  .woman-container {
  }
} */
/* @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .woman-container {
    height: 70%;
    z-index: 1;
    position: relative;
    /* width: 500px; */
/* margin: 0 auto; */
/* margin: 0 auto; */
/* border: solid green; */

/* margin: 0 auto; */
/* margin: 0 auto; */
/* border: solid green; */
/* margin-top: 20vh; */

.lady-arms {
  z-index: 5000;
  position: absolute;
  top: 9.1%;
  left: -4.3%;
  width: 117.4%;
  z-index: 600;
}

/* blobs */
.center-blob {
  position: absolute;
  width: 55%;
  top: 45%;
  left: 25%;
}

.top-blob {
  position: absolute;
  width: 35%;
  right: -7%;
  top: 15%;
  z-index: -1;
}

.bottom-blob {
  position: absolute;
  width: 60%;
  left: 25%;
  bottom: 0;
}

/* links */
.link {
  position: absolute;
  color: white;
  font-weight: 200;
  z-index: 8000;
}

.left-blob-link {
  /* right: 5%;
  top: -24%; */
  right: 0%;
  top: -24%;
}
@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
      .left-blob-link {
        /* right: 5%;
        top: -24%; */
        right: 0%;
        top: -20%
}}
@media (min-width: 415px) {
  .left-blob-link {
    top: -17%;
    font-size: 24px;
  }
}
@media (min-width: 1000px) {
  .left-blob-link {
    top: -20%;
    right: 115%;
    font-size: 1.3em;
  }
}
/* @media (min-width: 1000px) {
  .left-blob-link {
    font-size: 18px;
  }
} */
@media (min-width: 1900px) {
  .left-blob-link {
    font-size: 1.8em;
  }
}

.top-blob-link {
  right: -1%;
  color: #000000;
  top: 18%;
  font-size: 14px;
}
@media (min-width: 415px) {
  .top-blob-link {
    right: 0%;
    /* border: solid blue;
     */
    font-size: 1.20em;
  }
}

@media (min-width: 1900px) {
  .top-blob-link {

    font-size: 1.55em;
  }
}

.bottom-blob-link {
  left: 35%;
  bottom: 2.5%;
  font-weight: 900;
  font-size: 14px;
}

@media (min-width: 415px) {
  .bottom-blob-link {
    bottom: 3%;
    font-size: 1.15em;

  }
}
@media (min-width: 1900px) {
  .bottom-blob-link {
    font-size: 1.25em;
  }
}

.center-blob-link {
  z-index: 10000;
  top: 60%;
  left: 43%;
  font-size: 100%;
  font-weight: 900;
}
@media (min-width: 415px) {
  .center-blob-link {
    font-size: 22px;
  }
}

@media (min-width: 1900px) {
  .center-blob-link {
    font-size: 1.55em;

  }
}
.planet-1 {
  display: none;
}
@media (min-width: 800px) {
  .planet-1 {
    display: block;
    position: absolute;
    top: 664px;
    right: 24px;
    /* border: solid green; */
    max-width: 200px;
    width: 20vw;
  }
}
@media (min-width: 1000px) {
  .planet-1 {
    right: 64px;
    /* border: solid purple; */
  }
}
.planet-2 {
  display: none;
}
@media (min-width: 800px) {
  .planet-2 {
    display: block;
    position: absolute;
    top: 424px;
    max-width: 200px;
    left: 5vw;
    /* border: solid blue; */
    width: 20vw;
  }
}
/* @media (min-width: 769px) {
  .planet-2 {
    top: 624px;
  }
} */

@media (width: 1024px) and (height: 1366px) {
  .planet-2 {
    top: 624px;
  }
}
.planet-3 {
  position: absolute;
  top: 4px;
  right: 0;
  /* border: solid purple; */
  width: 20vw;
  z-index: 1;
}
@media (min-width: 415px) {
  .planet-3 {
    top: 100px;
    right: 5vw;
    /* height: 40vh; */
  }
}
/* @media (min-width: 800px) {
  .planet-3 {
    right: 4%;
    width: 18vw;
    max-width: 200px;

    /* height: 40vh;
  }
} */

@media (min-width: 800px) {
  .planet-3 {
    right: 6%;
    width: 10vw;
    min-width: 150px;
    max-width: 200px;
    /* border: solid wheat; */

    /* height: 40vh; */
  }
}

@media (min-width: 1500px) {
  .planet-3 {
    right: 8%;

    /* height: 40vh; */
  }
}
.home-about-tex-dark {
  color: white;
}

.home-about-text-light {
  color: black;
}
@media (min-width: 1000px) {
  .home-about-text-light {
    color: white;
  }
}
