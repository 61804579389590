.reason-to-join {
  padding: 2px 16px 2px 16px;
  text-align: left;
  margin-bottom: 16px;
}
@media (min-width: 900px) {
  .reason-to-join {
    width: 32%;
  }
}

.reason-title {
  font-size: 16px;
  font-weight: 700;
  /* font-size: 1.15em; */
}
.reason-title::after {
  content: "";
  border-top: 2px solid #000;
  display: block;
  width: 25px;
  margin: 10px 0;
}
.reason-title-dark-mode{
  font-size: 16px;
  font-weight: 700;
  /* font-size: 1.15em; */
}
.reason-title-dark-mode::after {
  content: "";
  border-top: 2px solid white;
  display: block;
  width: 25px;
  margin: 10px 0;
}
.reasons-body {
  font-size: 16px;
  font-weight: 300;
  height: min-content;
}
@media (min-width: 900px) {
.reasons-body {
  min-height: 280px;
}
}

.reasons-link {
  font-weight: 500;
}

.reasons-link:before {
  speak: none;
  font-style: normal;
  font-weight: 500;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  font-size: 16px;
  /* content: "\e602"; */
  content: "→";
  margin-right: 5px;
}
/* .reason-delimiter {
  border-top: solid black;
  width: 25px;
} */
