.BrainhubCarousel {
  height: min-content;
}
/* @media (min-width: 415px) {
  .BrainhubCarousel {
    min-height: unset;
  }
} */
ul li {
  color: #67b383;
}

.BrainhubCarousel__dots {
}
.item {
  position: relative;
  /* border: solid 1px black; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-project-bolder {
  font-weight: bolder;
  margin: unset;
}
.p-project-color {
  color: black;
}
.p-project-color-green {
  color: #6fbe95;
}
.font-size-16px {
  font-size: 16px;
  font-weight: 700;
}
.font-size-15px {
  font-size: 15px;
  font-weight: 700;
}

.font-size-13px 
{
  font-size: 13px;
  font-weight: 700;
}

.item-name {
  margin: 8px 0;
  /* margin-top: 92px; */
  margin-top:40px ;
  font-size: 24px;
  text-align: center;
  /* border: solid purple; */
}
@media (min-width: 415px) {
  .item-name {
    margin-top:unset;
    font-size: 32px;
  }
}

@media (min-width: 1000px) {
  .item-name {
    padding-right: 42px;
    /* border: solid wheat; */
  }
}

@media (min-width: 1000px) {
  .item-name {
    /* margin-top: 12%; */
    /* margin-top: 92px; */

    /* border: solid purple; */
  }
}

.item-date {
  /* border: solid red; */
  display: flex;
  width: 50%;
  text-align: center;
}
.item-keywords {
  /* border: solid black 1px; */
  padding: 2px;
  display: flex;
}
.item-about {
  /* border: solid black; */
}

.item-about-sub {
  /* border: solid blue; */
  display: none;
  font-size: 16px;
  /* border: solid orchid; */

  /* border: solid; */
}
@media (min-width: 415px) {
  .item-about-sub {
    display: block;
    /* border: solid yellow; */
  }
}

.item-about-sub-phone {
  display: block;
  border-radius: 0 0 4px 4px;
  background: #f7f7f7;
  padding: 0 8px 2px 16px;

  /* border: solid green; */
}

@media only screen and (min-width: 415px) {
  .item-about-sub-phone {
    display: none;
  }
}

.blob-container {
  justify-content: center;
  padding: 5%;
  align-items: center;
}
.project-slide-blob-wrapper {
  outline: none;
  /* width: 100%; */
  display: none;

  /* max-height: 232px; */

  /* border: solid orange; */
}
@media (min-width: 1300px) {
  .project-slide-blob-wrapper {
    max-height: unset;
    display: flex;
    margin: 0 auto;
    height: 100%;
    align-items: center;
    height: 100%;
  }
}

.project-slide-wrapper {
  outline: none;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  margin: 0 auto;
  /* border: solid orange; */
}
@media (min-width: 415px) {
  .project-slide-wrapper {
    padding-top: 108px;
    padding-top: 0px;
  }
}
@media (min-width: 1000px) {
  .project-slide-wrapper {
    padding-top: 0px;
    outline: none;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    /* height: 750px; */
    margin: 0 auto;
    margin-left: 42px;
  }
  /* border: solid orange; */
}
@media (min-width: 1100px) {
  .project-slide-wrapper {
    flex-direction: row;

    /* border: solid orange; */
  }
}

.about-content-wrapper {
  overflow: auto;
  height: 100%;
  /* border: solid green; */
  max-height: 315px;
}
@media (min-width: 415px) {
  .about-content-wrapper {

    max-height: unset;
    /* border: solid green; */
  }
}

@media (min-width: 1100px) {
  .about-content-wrapper {
    /* width: 694px; */
    max-height: unset;
    /* border: solid wheat; */
  }
}

.project-slide-container {
  /* border: solid green; */

  /* max-width: 800px; */
  max-height: 800px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 415px) {
  .project-slide-container {
    min-width: 400px;
    min-height: 400px;
  }
}
.blob-project {
  width: 178px;
  height: 178px;
  position: relative !important;
}
@media (min-width: 415px) {
  .blob-project {
    top: 74px;
    width: 300px;
  }
}

.project-scroll-icon {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  width: 100%;
}

.p-content {
  font-size: 16;
}

@media (min-width: 415px) {
  .p-project-content {
    font-size: 16px;
    padding-right: 28px;
  }
}

.margin-0 {
  margin: 0;
}
.padding-bottom-10px {
  padding-bottom: 10px;
}
