body {
  padding: 0;
  margin: 0;
}
/* .carousel {
  overflow: hidden;
  height: 100%;
} */
.slider-frame {
  height: 100% !important;
}
.slider {
  height: 80% !important;
}
.text-align-center {
  text-align: center;
  /* border: solid green; */
  width: 100%;
}

.slider:focus {
  outline: none !important;
}
.slider-list:focus {
  outline: none !important;
}
.slider-frame:focus {
  outline: none !important;
}
.slider-slide:focus {
  outline: none !important;
}
.overflow-hidden {
  overflow: hidden;
}
.project-container {
  width: 100%;
  min-width: 1000px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 84px;
}
@media (min-width: 415px) {
  .project-container {
    /* height: 100vh; */
    height: 100%;
    min-width: 875px;
    padding-top: 0;
    /* background: #222229; */
  }
}

.item {
  position: relative;
  /* border: solid 1px black; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fish-blob {
  height: 100%;
  width: 100%;
  z-index: -1;
  /* border: solid red; */
}
.BrainhubCarousel__arrow--disable {
  pointer-events: auto !important;
}

.font-weight-300 {
  font-weight: 300;
}
.font-weight-links {
  font-weight: 600;
}

.carousel-wrapper {
  width: 100%;
  z-index: 4000;
  margin-top: 73px;

  outline: none;
  display: none;
  /* border: solid green; */
}

@media (min-width: 415px) {
  .carousel-wrapper {
    display: block;
    width: 85%;
    /* padding-top: 40px; */
    max-width: 1200px;
    /* min-width: 900px; */
    /* border: solid blue; */
  }
}

.carousel-wrapper-phone {
  width: 85%;
  z-index: 4000;
  outline: none;
  /* border: solid green; */
}

@media (min-width: 415px) {
  .carousel-wrapper-phone {
    display: none;
    width: 85%;
    max-width: 1200px;
    /* min-width: 900px; */
    /* border: solid blue; */
  }
}

.about-us-title-projects {
  margin: unset;
  /* font-size: 1.8vw; */
  font-size: 26px;
  font-weight: 900;
}
@media (min-width: 415px) {
  .about-us-title-projects {
    font-size: 42px;
  }
}
@media (min-width: 1100px) {
  .about-us-title-projects {
    /* margin-bottom: 92px; */
  }
}

.dots-style {
  background: white;
}

.min-width-projects {
}

@media (min-width: 415px) {
  .min-width-projects {
    min-width: 800px;
  }
}

.dots-wrapper {
  position: relative;
  padding-top:20px;
}
@media (min-width: 415px) {
  .dots-wrapper {
    top: 0px;
    padding-top: 36px;
  }
}

.project-dots {
  width: 65%;
}
@media (min-width: 415px) {
  .project-dots {
    width: 75%;
  }
}

.project-arrow {
  position: relative;
  /* top: 120px; */
}
.project-arrow:hover{
  background-color:  rgba(0, 0, 0, 0.01) !important;
}
.project-arrow-disabled{
  position: relative;
}

.project-arrow-disabled:hover{
  background-color: unset !important;
}
.project-arrow-left {
}
@media (min-width: 415px) {
  .project-arrow {
    top: 0px;
  }
}
