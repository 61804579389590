.container {
  display: flex;
  justify-content: center;
  /* border: solid wheat; */
  width: 94%;
  /* z-index: 9000; */
}
@media (min-width: 415px) {
  .container {
    width: 100%;
  }
}

.svg-eye {
  pointer-events: none;
  position: absolute;
  top: 10%;
  left: 50%;
  width: 20%;
  max-width: 600px;
  transform-origin: 0% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 10em;
  z-index: 9000;
  /* border: solid blue;*/
}

.left-eye {
  z-index: 6000;
  top: 22.05%;
  left: 43%;
  width: 10%;
}
.right-eye {
  z-index: 6000;
  top: 21.65%;
  left: 59.1%;
  width: 10%;
}
@media (min-width: 415px) {
  .left-eye {
  z-index: 6000;
  top: 22.4%;
  left: 43%;
  width: 10%;
}}
@media (min-width: 415px) {

.right-eye {
  z-index: 6000;
  top: 21.9%;
  left: 59.1%;
  width: 10%;
}
}

#bg {
  fill: #2da1dc;
}

#eye > path:nth-child(2) {
  fill: #67b383;
}
#eye > g:first-of-type > path:nth-child(1) {
  fill: #67b383;
}
#eye > g:first-of-type > path:nth-child(2) {
  fill: #67b383;
}
#eye > g:last-of-type > path:nth-child(2) {
  fill: #231f20;
}
