.canvas {
  /* position: fixed; */
  position: absolute;
  /* top: 450px; */
  /* window: 100%; */
  height: 100%;
  z-index: 500;
  /* border: solid red; */
}

.blob-center {
  left: -20%;
  top: 17%;
}

.blob-right {
  left: 14%;
  top: -25%;
  z-index: -1;
}
.blob-left {
  display: none;
  /* left: -500px; */
}
/* @media (min-width: 1025px) IPAD PRO { */
@media (min-width: 1000px) {
  .blob-left {
    /* border: solid red; */
    display: block;
    left: -102%;
    top: -50%;
    /* left: -500px; */
  }
}

.blob-bottom {
  left: -22%;
  top: 42%;
}
