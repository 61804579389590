@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("./projects.css");
@import url("./home.css");
* {-webkit-font-smoothing: antialiased;}
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background-dark {
  background: #222229;
}
.p-color-light {
  color: white;
}
.p-color-dark {
  color: black !important;
}
html,
body {
  margin: 0;
  padding: 0;
}

p {
  /* font-family: Poppins; */
  /* font-size: 20px; */
  font-weight: 200;
}
.wrapper {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
}

.pyramid {
  width: 100px;
  height: 100px;
  /* background-color: green; */
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 1;
}
.timer {
  display: none;
}
@media (min-width: 415px) {
  .timer {
    display: block;
    position: absolute;
    /* color: white; */
    top: 30px;
    right: 2%;
    font-size: 24px;
  }
}
.location-icon {
  position: relative;
  height: 12px !important;
  margin: 0px !important;
  margin-right: 0px !important;
  margin-top: 6.6px !important;
}
@media (min-width: 415px) {
  .location-icon {
    height: 16px !important;
    margin-right: 5px !important;
    margin-top: 5px !important;
  }
}
.contact-alternative {
  display: flex;
  justify-content: center;
  /* border: solid red; */
  position: relative;
  padding-top: 16px;
  z-index: 1;
  /* top: 10%; */
}
@media (min-width: 415px) {
  .contact-alternative {
    /* padding-bottom: 100px; */
    padding-left: 32px;
    justify-content: flex-start;
  }
}


@media (width: 1024px) and (height: 1366px) {
  .contact-alternative {
    /* margin-top: 30%; */
    /* border: solid blue; */
  }
}
.contact {
  position: absolute;
  display: flex;
  /* color: white; */
  top: 570px;
  left: 9%;
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
}
@media (min-width: 361px) {
  .contact {
    top: 590px;
  }
}
@media (min-width: 376px) {
  .contact {
    left: 13%;
    top: 660px;
  }
}
@media (min-width: 415px) {
  .contact {
    left: 48px;
    top: 1140px;
  }
}
@media (width: 375px) and (height: 812px) {
  .contact {
    top: 700px;
  }
}
/* ipad  */

@media (width: 768px) and (height: 1024px) {
  .contact {
    left: 48px;
    top: 950px;
    /* border: solid green; */
  }
}
/* ipad proo */
@media (width: 1024px) and (height: 1366px) {
  .contact {
    left: 48px;
    top: 1300px;
  }
}
.contact-delimiter {
  margin-left: 8px;
  margin-right: 8px;
  height: 1em;
}
/* @media (min-width: 415px) {
  .contact-delimiter {
    margin-left: 20px;
    margin-right: 20px;
    height: 2em;
  }
} */
.contact-delimiter-email {
  display: none;
  margin-left: 8px;
  margin-right: 8px;
}
@media (min-width: 600px) {
  .contact-delimiter-email {
    display: block;
  }
}

.contact-emails-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .contact-emails-container {
    flex-direction: row;
  }
}
.contact-address-text {
  min-width: 105px;
}

.contact-text-home {
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 16px;
}
@media (min-width: 415px) {
  .contact-text-home {
    font-size: 14px;
  }
}
.img-container {
  height: 90vh;
  width: 80%;
  display: flex;
  justify-content: center;
  position: relative;
}

.woman {
  height: 93%;
  align-self: flex-end;
  z-index: -1;
}

.arms {
  position: absolute;
  height: 87%;
  margin-left: 20px;
  align-self: flex-end;
  width: 48.5%;
  z-index: 1;
}

.who-we-are {
  position: absolute;
  top: 0px;
  left: 6%;
}

.us-blob {
  position: absolute;
  top: 0.2rem;
  left: 12rem;
}

.us-blob-text {
  color: white;
  position: absolute;
  top: 4.2rem;
  left: 17rem;
  text-align: right;
  z-index: 2;
}

.work-blob {
  position: absolute;
  bottom: 0;
}

.work-link {
  position: absolute;
  width: 188px;
  height: 30px;
  bottom: 4.8vh;
  left: 42%;
  z-index: 2;
}

.work {
  position: absolute;
  bottom: 4.5vh;
  background-color: rgb(255, 255, 255, 0.5);
  width: 188px;
  height: 25px;
  margin-left: 5px;
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
}

.rectangle {
  position: absolute;
  width: 240px;
  bottom: 0;
  left: 41%;
}

.projects-blob {
  position: absolute;
  z-index: 0;
  bottom: 9vh;
  margin-left: 2rem;
}

.projects {
  position: absolute;
  top: 65%;
  font-weight: bolder;
}

.projects-link {
  position: absolute;
  width: 12rem;
  height: 12rem;
  bottom: 17%;
  left: 43%;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
}

.about-blob {
  position: absolute;
  width: 180px;
  margin-left: 19%;
  top: 22%;
  z-index: 1;
}

.about-link {
  position: absolute;
  width: 6rem;
  height: 6rem;
  top: 25%;
  right: 28%;
  cursor: pointer;
  z-index: 2;
}

.triangle {
  position: absolute;
  width: 180px;
  margin-left: 18.5%;
  top: 19%;
  z-index: 2;
}

.about {
  position: absolute;
  top: 29%;
  margin-left: 18%;
  z-index: 1;
}

.blob-enter {
  opacity: 0.01;
}

.blob-enter.blob-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.blob-leave {
  opacity: 1;
}

.blob-leave.blob-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
.logo {
  width: 60px;
  z-index: 2;
  position: absolute;
  top: 14px;
  left: 35px;
  max-width: 400px;

  /* border: solid blue; */
}
@media (min-width: 415px) {
  .logo {
    width: 80px;
    z-index: 2;
    position: absolute;
    top: 14px;
    left: 35px;
    max-width: 400px;
  
    /* border: solid blue; */
  }
}

.margin-y-woman-container {
  margin-top: 80px;
  margin-bottom: 0;
  margin-bottom: 50px;

}
@media (min-width: 415px) {
  .margin-y-woman-container {
    margin-top: 80px;
    margin-bottom: 10px;

  }
}
@media (min-width: 1900px) {
  .margin-y-woman-container {
    margin-bottom: 100px;
  }
}
.p-color-white {
  color: white;
}
.p-color-dark {
  color: black;
}
.remove-link-decoration {
  text-decoration: none;
  color: inherit;
}
.remove-link-color {
  color: inherit;
}
.border-solid-green {
}

.font-weight-900 {
  font-weight: 900;
}

.font-size-32px {
  font-size: 22px !important;
}
@media (min-width: 800px) {
  .font-size-32px {
  font-size: 32px !important;
}}

.not-found-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  align-items: center; 
}

.not-found-image{
  width: 80%;
  margin: 0 10%;
  height: auto;}

.not-found-text-dark-mode{
  color:white
}
.not-found-text-light-mode{
  color:black
}
.margin-top-42px{
  margin-top: 42px !important;
}
.margin-top-40px {
  margin-top: 40px !important;


}

.alternative-slogan{
  text-align:'center'


}