.display-flex-row {
  display: flex;
  flex-direction: row;
}
.work-content {
  height: 100%;
  width: 90%;
  margin: auto;
  padding-left: 16px;
}
@media (min-width: 360px) {
  .work-content {
    width: 100%;
  }
}
@media (min-width: 376px) {
  .work-content {
    padding-left: 0;
  }
}

.work-container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1000px) {
  .work-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 6% 0 40px 0;
  }
}
.work-content-row {
  display: flex;
  width: 100%;
  max-width: 350px;
  flex-direction: column;
}

@media (min-width: 415px) {
  .work-content-row {
    min-width: 600px;
    box-shadow: unset !important;
  }
}
@media (min-width: 600px) {
  .work-content-row {
    box-shadow: unset !important;
  }
}

@media (min-width: 1100px) {
  .work-content-row {
    display: flex;
    flex-direction: row-reverse;
    min-width: 1000px;
    width: 100%;
    min-height: 400px;
    height: 100%;
    justify-content: space-evenly;
  }
}

.work-content-row-text {
  width: 100%;
}
@media (min-width: 800px) {
  .work-content-row-text {
    width: 100%;
  }
}
.work-content-row-animation {
    width: 100%;
    padding-left: 17px;

}
@media (min-width: 415px) {
.work-content-row-animation {
  padding-left: unset
  
}}
.animation-phone-dark-background {
  background: #34343e;
  border-radius: 4px 4px 0 0;
}
@media only screen and (min-width: 415px) {
  .animation-phone-dark-background {
    background: inherit;
    border-radius: unset;
  }
}

@media (min-width: 800px) {
  .work-content-row-animation {
    margin-left: 107px;
    width: 100%;
    height: 50%;
  }
}

.height-100procent {
  height: 100%;
}
.blob-work-wrapper {
  width: 100%;
}
.contact-blob {
  position: relative;
}
@media (min-width: 415px) {
  .contact-blob {
    width: unset;
    position: relative;
  }
}
@media (min-width: 1100px) {
  .contact-blob {
    top: 660px;
    position: absolute;
    right: 10vw;
  }
}

.contact-text {
  position: relative;
  top: 46px;
  left: 53%;
  width: 201px;
  text-align: center;
}
@media (min-width: 415px) {
  .contact-text {
    left: 92.5%;
    top: 128px;
  }
}
@media (min-width: 1000px) {
  .contact-text {
    height: 300px;
    left: 54%;
    width: 201px;
    top: 94px;
  }
}
.p-contact-text {
  z-index: 10000;
  color: white;
  position: relative;
}
.blob-work {
  position: relative;
  width: 187px;
  height: 187px;
  left: 57%;
  top: -73px;
}
@media (min-width: 415px) {
  .blob-work {
    left: 83%;
    width: 246px;
    height: 246px;
    top: -23px;
  }
}
@media (min-width: 1000px) {
  .blob-work {
    top: 0;
    left: 43%;
    position: absolute;
  }
}
.work-expand {
  display: flex;
  justify-content: space-between;
}
.line-height-position {
  line-height: 1.4em;
}

.margin-bottom-79px {
  margin-bottom: 79px;
}
